import Layout from "@/layouts"

const settingsCategory = {
  path: "/settings-category",
  component: Layout,
  name: "settingsCategory",
  redirect: "/settings-category/index",

  children: [
    {
      path: "/settings-category/index",
      component: () => import("@/views/settingsCategory/index"),
      name: "settingsCategory.index",
      meta: { title: "settings Category" },

    },
    {
      path: "",
      component: () => import("@/views/settingsCategory/_slug"),
      name: "settingsCategory.slug",
      meta: { title: "slug" },

      children: [
        {
          path: "/settings-category/systems/index",
          component: ()=> import("@/views/systems/index"),
          name: "systems.index",
          meta: {
            title: "Systems",
          },

        },
        {
          path: "/settings-category/permissions/index",
          component: () => import("@/views/permissions/index"),
          name: "permissions.index",
          meta: {
            title: "Permissions",
          },

        },
        {
          path: "/settings-category/roles/index",
          component: () => import("@/views/roles/index"),
          name: "roles.index",
          meta: {
            title: "Roles",
          },

        },
        {
          path: "/settings-category/users/index",
          component: () => import("@/views/users/index"),
          name: "users.index",
          meta: {
            title: "Users",
          },

        },
      ],
    },
  ],
}

export default settingsCategory
