export const model = {
    id: null,
    name : '',
    domen : '',
    user_amount : '',
    purchase_type : '',
    purchase_price : 0,
    integration : '',
    integration_price : 0,
    enabled: false,
    created_at: "",
    updated_at: ''
};
