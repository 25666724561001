export const filter = {
    id : '',
    name : '',
    domen : '',
    user_amount : '',
    purchase_type : '',
    purchase_price : '',
    integration : '',
    integration_price : '',
    created_at: "",
    updated_at: ''
};
