import { actions } from './system/actions'
import { mutations } from './system/mutations'
import { getters } from './system/getters'
import { state } from './system/state'
export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters
}
