import request from './../utils/request'

  export function index(params) {
    return request({
      url: '/systems',
      method: 'get',
        params
    })
  }

  export function inventory() {
    return request({
      url: '/systems/inventory',
      method: 'GET',
    })
  }

  export function show(id) {
    return request({
      url: `/systems/${id}`,
      method: 'get'
    })
  }

  export function store(data) {
    return request({
      url: '/systems',
      method: 'post',
      data
    })
  }

  export function update(data) {
    return request({
      url: `/systems/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/systems/${id}`,
      method: 'delete',
    })
  }
