import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: '№',
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    domen : {
        show: true,
        title: i18n.t('message.domen'),
        sortable: true,
        column: 'domen'
    },
    user_amount : {
        show: true,
        title: i18n.t('message.user_amount'),
        sortable: true,
        column: 'user_amount'
    },
    purchase_type : {
        show: true,
        title: i18n.t('message.purchase_type'),
        sortable: true,
        column: 'purchase_type'
    },
    purchase_price : {
        show: true,
        title: i18n.t('message.purchase_price'),
        sortable: true,
        column: 'purchase_price'
    },
    integration : {
        show: true,
        title: i18n.t('message.integration'),
        sortable: true,
        column: 'integration'
    },
    integration_price : {
        show: true,
        title: i18n.t('message.integration_price'),
        sortable: true,
        column: 'integration_price'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.update_date'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};
