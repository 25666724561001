import Layout from '@/layouts'

const rolesRouter = {
    path: '/adminPanel',
    component: Layout,
    name: 'adminPanel',
    redirect: '/adminPanel/index',
    children: [
        {
          path: 'adminPanel/index',
          component: () => import('@/views/adminPanel/index'),
          name: 'adminPanel.index',
          meta: { title: 'adminPanel'}
        },
    ]
}

export default rolesRouter